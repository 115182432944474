@import url(https://use.typekit.net/uxo3pvo.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.scrollUp {
  transform: translateY(-14vh);
}

.transpNav {
  background-color: #f5f0e8 !important;
}

.transpNavTxt {
  color: #252831 !important;
}

.navTxt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hide {
  opacity: 0;
}

.typewriter span {
  border-right: .05em solid;
  -webkit-animation: caret 1s steps(1) infinite;
          animation: caret 1s steps(1) infinite;
}

@-webkit-keyframes caret {
  50% {
    border-color: transparent;
  }
}

@keyframes caret {
  50% {
    border-color: transparent;
  }
}

body {
  width: 100%;
  height: 200vh;
  max-width: 100%;
  overflow-x: hidden;
}

body .page {
  display: none;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
}

body #burger {
  visibility: hidden;
  z-index: 50001;
  width: 40px;
  position: fixed;
  margin: 30px auto;
  left: 5%;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
}

body #burger span {
  display: block;
  position: absolute;
  height: 7px;
  width: 100%;
  background: #bd3f34;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

body #burger span:nth-child(1) {
  top: 0px;
}

body #burger span:nth-child(2), body #burger span:nth-child(3) {
  top: 14px;
}

body #burger span:nth-child(4) {
  top: 28px;
}

body #burger.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

body #burger.open span:nth-child(2) {
  transform: rotate(45deg);
}

body #burger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

body #burger.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

body .ca-logo {
  z-index: 50000;
  height: 80px;
  position: fixed;
  left: 25px;
  top: 2%;
  transition: all 0.5s;
}

body .open {
  margin-left: 0vw !important;
}

body nav.mobile {
  visibility: hidden;
  position: fixed;
  width: 40vw;
  height: 100vh;
  z-index: 50000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s;
  margin-left: -40vw;
}

body nav.mobile #content {
  background-color: #252831;
  width: 60vw;
  height: 100vh;
  box-shadow: inset -6px 0px 19px #131418;
  transition: all 1s;
}

body nav.mobile #content .links {
  padding-top: 40%;
  height: 70%;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: (1fr)[7];
      grid-template-rows: repeat(7, 1fr);
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
}

body nav.mobile #content i {
  transition: transform .5s;
}

body nav.mobile #content .involve:hover > i {
  transform: rotate(90deg);
}

body nav.mobile #content .gap {
  width: 25%;
}

body nav.mobile #content a {
  color: #f5f0e8;
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 120%;
  transition: 2s all;
  text-decoration: none;
  text-align: center;
}

body nav.mobile #content .ulLeft {
  width: 0%;
  height: 1px;
  background: #E57D73;
  /* For Safari 3.1 to 6.0 */
  transition: width 1s;
}

body nav.mobile #content a:hover > .ulLeft {
  width: 100%;
}

body nav.mobile #content .ulRight {
  width: 0%;
  margin-left: 100%;
  height: 1px;
  background: #E57D73;
  /* For Safari 3.1 to 6.0 */
  transition: width 1s;
  transform: translateX(-100%);
}

body nav.mobile #content a:hover > .ulRight {
  width: 100%;
}

body nav.default {
  width: 100%;
  height: 14vh;
  position: fixed;
  z-index: 5000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.5s;
}

body nav.default .links {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

body nav.default svg {
  transition: all .5s;
}

body nav.default .involve {
  flex-direction: row;
}

body nav.default .involve:hover > svg {
  transform: rotate(90deg);
  color: #e9b825;
}

body nav.default .gap {
  width: 25%;
}

body nav.default a {
  color: #f5f0e8;
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 120%;
  transition: 2s all;
  text-decoration: none;
}

body nav.default a:hover {
  font-size: 150%;
}

body nav.default .ulLeft {
  width: 0px;
  height: 1px;
  background: #E57D73;
  /* For Safari 3.1 to 6.0 */
  transition: width 1.5s;
}

body nav.default a:hover > .ulLeft {
  width: 100%;
}

body nav.default .ulRight {
  width: 0%;
  height: 1px;
  background: #E57D73;
  /* For Safari 3.1 to 6.0 */
  transition: width 1s;
}

body nav.default a:hover > .ulRight {
  width: 100%;
}

body .header {
  z-index: 100;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #252831;
}

body .header .background {
  background-image: url(/class.jpg);
  background-size: cover;
  -webkit-filter: blur(3px) grayscale(100) opacity(0.5);
          filter: blur(3px) grayscale(100) opacity(0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  transition: all 2s;
}

body .header .diversity {
  font-family: code-saver,sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 50px;
  z-index: 300;
  overflow: hrgb(230, 81, 68) idden;
  color: #bd3f34;
  margin: 0px !important;
}

body .header .diversity c {
  font-size: 9.5vw;
  margin-left: 38vw;
}

body .header h1 {
  width: 70%;
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 200px;
  margin: 0;
  text-align: center;
  color: #f5f0e8;
  z-index: 2;
}

body .header h1 c {
  font-size: 9.5vw;
  margin-left: 32vw;
}

body .header h2 {
  width: 50%;
  color: #f5f0e8;
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 120%;
  transition: 2s all;
  text-align: center;
  z-index: 2;
  position: absolute;
  bottom: 10vh;
  line-height: 130%;
}

body .header h2 c {
  color: #bd3f34;
}

body .content-1 {
  z-index: 300;
  width: 100%;
  height: 100vh;
  background-image: url(/static/media/noise-white.b0cb01b5.png);
  background-repeat: repeat;
}

body .content-1 .stats {
  position: absolute;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

body .content-1 .stats .quote {
  background-color: rgba(189, 63, 52, 0.9);
  z-index: 350;
  width: 30%;
  height: 100vh;
  position: absolute;
  left: 7%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body .content-1 .stats .quote h2 {
  width: 100%;
  color: #252831;
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 250%;
  transition: 2s all;
  text-align: center;
  line-height: 130%;
}

body .content-1 .stats .quote p {
  padding-top: 3%;
  width: 100%;
  color: #252831;
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 90%;
  transition: 2s all;
  text-align: center;
  line-height: 130%;
}

body .content-1 .stats .quote p a {
  color: #565b6b;
  transition: all .7s;
}

body .content-1 .stats .quote p a:hover {
  color: #f5f0e8;
}

body .content-1 .stats .chart {
  margin-top: 4%;
  position: absolute;
  width: 40%;
  height: 75vh;
  z-index: 350;
  right: 18%;
}

body .content-1 .stats .chart h3 {
  width: 100%;
  color: #252831;
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 120%;
  transition: 2s all;
  text-align: center;
  line-height: 130%;
}

body .content-1 .stats .chart p {
  padding-top: 5%;
  width: 100%;
  color: #252831;
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 90%;
  transition: 2s all;
  text-align: center;
  line-height: 130%;
}

body .content-1 .stats .chart p a {
  color: #565b6b;
  transition: all .7s;
}

body .content-1 .stats .chart p a:hover {
  color: #bd3f34;
}

body .content-1 .back-text1 {
  cursor: default;
  width: 100%;
  height: 100vh;
  font-family: acier-bat-gris,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 50vw;
  color: #252831;
  opacity: .1;
  overflow: hidden;
  position: absolute;
  z-index: 301;
  text-align: center;
}

body .content-2 {
  z-index: 00;
  background-image: url(/static/media/noise-blue.9c5743a5.png);
  background-repeat: repeat;
  position: relative;
  display: flex;
  flex-direction: column;
}

body .content-2 h2 {
  width: 100%;
  height: 15%;
  padding-top: 40px;
  text-align: center;
  color: #f5f0e8;
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-size: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

body .content-2 .cards {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

body .content-2 .cards .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: space-around;
}

body .content-2 .cards a {
  cursor: pointer;
  height: 70%;
  width: 20%;
  background-color: #f5f0e8;
  background-image: url(/static/media/noise-white.b0cb01b5.png);
  background-repeat: repeat;
  border-radius: 15px;
  opacity: .9;
  z-index: 350;
  box-shadow: black 0px 5px 15px;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

body .content-2 .cards a img {
  width: 150px;
  visibility: visible;
}

body .content-2 .cards a h2 {
  font-family: acier-bat-gris,sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #252831;
}

body .content-2 .cards a:hover {
  opacity: 1;
  box-shadow: black 0px 10px 15px;
  transform: translateY(-10px);
}

body .content-2 .cards p {
  color: #252831;
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  width: 80%;
  visibility: visible;
  font-size: .9vw;
}

body .content-2 .back-text2 {
  cursor: default;
  width: 100%;
  height: 100vh;
  font-family: acier-bat-gris,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 50vw;
  color: #f5f0e8;
  opacity: .2;
  overflow: hidden;
  position: absolute;
  z-index: 301;
  text-align: center;
}

body .subscribe {
  z-index: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 30vh;
  background-color: #bd3f34;
}

body .subscribe label {
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 250%;
  color: #f5f0e8;
  margin-bottom: 50px;
}

body .subscribe #CustomFormContainer {
  z-index: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

body .subscribe #CustomFormContainer .alert {
  background-color: #8d1f15;
  position: absolute;
  color: #f5f0e8;
  padding: 10px;
  border-radius: 20px;
  font-size: 4vw;
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
  width: 90%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

body .subscribe #CustomFormContainer #mce-EMAIL {
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  background: none;
  border: none;
  border-bottom: solid #f5f0e8 3px;
  width: 30%;
  height: 8vh;
  text-align: center;
  color: #f5f0e8;
  letter-spacing: .5vw;
  transition: all .5s;
  font-size: 1.5em;
}

body .subscribe #CustomFormContainer #mce-EMAIL::-webkit-input-placeholder {
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: #f5f0e8;
  letter-spacing: .5vw;
}

body .subscribe #CustomFormContainer #mce-EMAIL:-ms-input-placeholder {
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: #f5f0e8;
  letter-spacing: .5vw;
}

body .subscribe #CustomFormContainer #mce-EMAIL::-ms-input-placeholder {
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: #f5f0e8;
  letter-spacing: .5vw;
}

body .subscribe #CustomFormContainer #mce-EMAIL::placeholder {
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  text-align: center;
  color: #f5f0e8;
  letter-spacing: .5vw;
}

body .subscribe #CustomFormContainer #mce-EMAIL:hover {
  -webkit-filter: drop-shadow(0px 10px 5px #791f1f);
          filter: drop-shadow(0px 10px 5px #791f1f);
  transform: translateY(-2px);
}

body .subscribe #CustomFormContainer #mce-EMAIL:focus {
  -webkit-filter: drop-shadow(0px 10px 5px #791f1f);
          filter: drop-shadow(0px 10px 5px #791f1f);
  transform: translateY(-2px);
  outline: none;
}

body .subscribe #CustomFormContainer #subBttn {
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  height: 7vh;
  background: #8d1f15;
  border-radius: 50px;
  padding: 20px 2vw;
  color: #f5f0e8;
  border: none;
  font-weight: bold;
  letter-spacing: .5vw;
  transition: all .5s;
  box-shadow: #791f1f -1px 4px 16px 0px;
}

body .subscribe #CustomFormContainer #subBttn:hover {
  cursor: pointer;
  background: #a53227;
  box-shadow: #791f1f 0px 15px 16px 0px;
  transform: translateY(-2px);
}

body .partners {
  width: 100%;
  height: 40vh;
  background-image: url(/static/media/noise-white.b0cb01b5.png);
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body .partners h1 {
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2vw;
  color: #252831;
  padding-bottom: 50px;
  width: 100%;
  text-align: center;
}

body .partners .icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

body .partners .icons a {
  display: flex;
  justify-content: center;
  width: 15vw;
}

body .partners .icons a img {
  width: 9vw;
  transition: all 1s;
}

body .partners .icons a img:hover {
  -webkit-filter: drop-shadow(0px 10px 15px #000000);
          filter: drop-shadow(0px 10px 15px #000000);
  transform: translateY(-2px);
}

body .footer {
  width: 100%;
  height: 50vh;
  background-image: url(/static/media/noise-white.b0cb01b5.png);
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

body .footer .links, body .footer .base {
  width: 80%;
}

body .footer .links {
  height: 75%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: #c2c2c2 solid 2px;
}

body .footer .links img {
  width: 130px;
}

body .footer .links .list {
  display: flex;
  flex-direction: row;
  color: #252831;
}

body .footer .links .list .get-involved {
  padding-left: 50px;
}

body .footer .links .list h2 {
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
  color: #252831;
  text-align: left;
}

body .footer .links .list a {
  font-family: soleil,sans-serif;
  font-weight: 700;
  font-style: normal;
  color: #252831;
}

body .footer .links .list ul {
  list-style: none;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
}

body .footer .links .list ul li {
  padding: 5px 0;
}

body .footer .links .list ul li a {
  display: flex;
  flex-direction: row;
  justify-items: center;
  transition: all .7s;
}

body .footer .links .list ul li:hover > a {
  color: #bd3f34;
}

body .footer .base {
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #252831;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 25%;
  letter-spacing: .5vw;
}

body .footer .base .icon {
  font-size: 30px;
  font-weight: bold;
  color: #252831;
}

body.safari .partners {
  width: 100%;
  height: 40vh;
  background-image: url(/static/media/noise-white.b0cb01b5.png);
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

body.safari .partners h1 {
  font-family: acier-bat-solid,sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2vw;
  color: #252831;
  padding-bottom: 50px;
  width: 100%;
  text-align: center;
}

body.safari .partners .icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

body.safari .partners .icons a {
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
}

body.safari .partners .icons a img {
  width: 9vw;
  transition: all 1s;
}

body.safari .partners .icons a img:hover {
  -webkit-filter: drop-shadow(0px 10px 15px #000000);
          filter: drop-shadow(0px 10px 15px #000000);
  transform: translateY(-2px);
}
/*# sourceMappingURL=main.css.map */
